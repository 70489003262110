<template>
	<div class="main product" :class="sectionsClass">
		<div href="#" class="back-to-list" @click="backToList()"><i class="fas fa-chevron-left"></i> Back to {{ $route.query.from == 'calendar' ? "Calendar" : "List" }}</div>
		<h1>{{ detail.description }}</h1>

		<div class="">			
			<div class="two-columns grid-start">

				<div>
					<div class="image-wrapper">
						<img class="class-img" :src="detail.image_src" style="max-width: 80%;">
					</div>
					<div class="class-meta">
						<div class="product-price" v-html="$formatPrice(detail.price)"></div>

						<div v-if="detail.early_registration_closes_days_before && detail.early_registration_discount" style="margin: 1em 0;">
							<label>Early Registration Discount:</label>
							<p>${{detail.early_registration_discount }} if {{ detail.early_registration_closes_days_before }} {{ (detail.early_registration_closes_days_before > 1 ? 'days' : 'day') }} before class date</p>
						</div>

						<h3 v-if="detail.default_instructor">Instructor: {{ detail.default_instructor }}</h3>
						<h3 v-if="datesSelected.length > 0 && !classExpired">Seats Available: {{ detail.seats_available - reservationCount }}</h3>
						<h3 v-if="detail.registration_closes_days_before">Registration Ends: {{ detail.registration_closes_days_before }} days before start</h3>
					</div>
					<div class="description" v-html="detail.detailed_description"></div>
				</div>
				
				<div>
					<div v-if="datesSelected.length > 0">
						<div v-if="datesSelected.length > 1">This is a multi-session class...</div>
						<h2 style="margin: 0.5em 0 0.25em 0;">{{ datesSelected.length > 1 ? 'Sessions' : 'Date' }} Selected:</h2>
						<div v-for="(date, index) in datesSelected">{{ date }}</div>
						<div v-if="classExpired" style="margin: 0.25em 0 0 0; color: red;">Oops, this class is over!</div>
						<div v-if="detail.recurring" style="margin: 1em 0 0 0;">
							<button class="button action" @click="datesSelected = []">Change Date{{ datesSelected.length > 1 ? 's' : '' }}</button>
						</div>
					</div>
					<div v-else-if="detail.recurring || (detail.dates && detail.dates.length > 1)" class="small-class-calendar">
						<h2 v-if="detail.multi_session > 1">This class has {{ detail.multi_session }} sessions<br>Select a date for the first session</h2>
						<h2 v-else style="margin: 0.5em 0 1em 0;">Select a class date</h2>
						<calendar-view v-if="!loading"
							:show-date="showDate"
							:items="calendarItems"
							currentPeriodLabel="•"
							:periodChangedCallback="changeMonth"
							:showTimes=false
							@click-item="selectCalendarItem"
							class="theme-default holiday-us-traditional">
							<template #header="{ headerProps }">
								<calendar-view-header
									:header-props="headerProps"
									@input="setShowDate" />
							</template>
						</calendar-view>
					</div>

					<div v-if="datesSelected.length > 0 && !classExpired">
						<h2>Registration Info</h2>
						<div class="" style="grid-gap: 1.5em 2.5em;">
							<div class="form-group">
								<label>Number of Participants</label>
								<select v-model="seatsSelected" style="width: 6em;" @change="populateParticipants()">
									<option v-for="(seats, index) in (detail.seats_available - reservationCount)" :value="seats">{{ seats }}</option>
								</select>
							</div>
							<div></div>
					
							<div v-for="(participant, index) in participants" class="registration-section">
								<h4>Participant {{ index + 1 }} Info</h4>
								<div>
									<div class="form-group">
										<label>First Name</label>
										<input type="text" v-model="participant.first_name"/>
									</div>
									<div class="form-group">
										<label>Last Name</label>
										<input type="text" v-model="participant.last_name"/>
									</div>
									<div class="form-group">
										<label>Email Address</label>
										<input type="text" v-model="participant.email_address"/>
									</div>
									<div class="form-group">
										<label>Phone Number</label>
										<input type="text" v-model="participant.phone_number"/>
									</div>
									<div v-if="detail.enable_email_reminder || detail.enable_text_reminder" class="two-columns" style="margin-bottom: 1em;">
										<div v-if="detail.enable_email_reminder">
											<input checked type="checkbox" v-model="participant.email_notifications"/>
											<label>Send email notifications for class.</label>
										</div>
										<div v-if="detail.enable_text_reminder">
											<input checked type="checkbox" v-model="participant.text_notifications"/>
											<label>Send text notifications for class.</label>
										</div>
									</div>

									<template v-if="detail.registration_required_fields">
										<template v-if="detail.registration_required_fields.birthdate">
											<div class="form-group">
												<label>Participant Date of Birth</label>
												<input type="text" v-model="participant.birthdate"/>
											</div>
										</template>
										<template v-if="detail.registration_required_fields.emergency_contact">
											<div class="form-group">
												<label>Emergency Contact Name and Phone Number</label>
												<input type="text" v-model="participant.emergency_contact"/>
											</div>
											<div></div>
										</template>
									</template>

								</div>
							</div>

						</div>

						<div style="margin: 1em 0;">
							<div v-if="detail.registration_terms" style="margin-bottom: 1em;">
								<input type="checkbox" v-model="detail.agree_to_terms"/>
								<label>I have read and agree to the <a @click="rulesModalOpen = true">Rules and Requirements</a> for this class.</label>
							</div>
							<div>
								<button v-if="!inCart" :class="['button', addingToCart || !canAddToCart ? 'disabled' : 'action']" @click="addClassToCart()">{{ inCart ? 'Remove Registration from' : 'Add Registration to' }} Cart</button>
								<div style="margin: 1em 0;">{{ addingToCartMessage }}</div>
							</div>
							<div v-if="registrationIsClosed">Registration is closed</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		<div v-if="detail.products && detail.products.length > 0">
			<div class="related-products">
				<h2>Recommended items for this class</h2>
				<div class="section product-grid" style="margin-bottom: 0;">
					<div v-for="(product, index) in detail.products" class="product-container" :product="product">
						<a class="product-image-link"><img :src="product.image_src" class="thumbnail-image"/></a>
						<div class="product-title">
							{{ product.description }}
						</div>
						<div class="columns columns-2 product-meta-price">
							<div class="product-meta"><span v-if="product.in_store > 0">Available In Store</span><span v-else>ITEM: {{ product.product_id }}</span><div v-if="product.upc">UPC: {{ product.upc }}</div></div>
							<div class="product-price" v-if="product.price" v-html="$formatPrice(product.price)"></div>
						</div>
						<div class="columns columns-2 product-controls">
							<router-link tag="a" class="button primary product-detail-link" :to="'/product/' + product.link_name">Details</router-link>
							<button :class="['button', addingToCart ? 'disabled' : 'action', 'add-to-cart']" @click="addProductToCart()">Add to Cart</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="rulesModalOpen" class="modal large-modal">
			<div class="inner">
				<h2>Rules and Requirements</h2>
				<div class="content">
					<div v-html="detail.registration_terms"></div>
				</div>
				<div class="button cancel" @click="rulesModalOpen = false">Close</div>
			</div>
		</div>

	</div>

</template>

<script>
import CalendarView from "vue-simple-calendar/src/components/CalendarView.vue"
import CalendarViewHeader from "vue-simple-calendar/src/components/CalendarViewHeader.vue"
import moment from 'moment'
export default {
	name: 'classesdetail',
	props: ['link_name'],
	components: {
		'calendar-view': CalendarView,
		'calendar-view-header': CalendarViewHeader
	},
	data() {
		return {
			loading: false,
			loadingRelatedProducts: false,
			detail: {},
			participants: [],
			userContact: {},
			showDate: new Date(),
			datesSelected: [],
			classCalView: false,
			calendarItems: [],
			rulesModalOpen: false,
			seatsSelected: 1,
			addingToCart: false,
			addingToCartMessage: '',
			inCart: false,
			today: '',
			classExpired: false,
			reservationCount: 0
		}
	},
	created() {
        // we'll use showDate to compare with class dates, so set hours to zero
        this.showDate.setHours(0,0,0,0);
        this.today = this.showDate.getFullYear() + '-' + (this.showDate.getMonth()+1).toString().padStart(2,'0') + '-' + this.showDate.getDate().toString().padStart(2,'0')
		this.fetchData()
	},
	computed: {
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return
			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		},
        canAddToCart() {
			if (this.registrationIsClosed) {
				return false
			}
            if (this.datesSelected.length > 0 && this.detail.seats_available - this.reservationCount > 0 && this.participants[0].first_name && this.participants[0].last_name && this.participants[0].email_address && this.participants[0].phone_number) {
				return true
			}
			return false
		},
		registrationIsClosed() {
			let selectedDateParts = this.datesSelected[0].split(' ')
			let dateParts = selectedDateParts[0].split('/')
			let dateObject = new Date(dateParts[2], dateParts[0] - 1, dateParts[1])
			if (this.detail.registration_closes_days_before) {
				dateObject.setDate(dateObject.getDate() - this.detail.registration_closes_days_before)
			}
			let currentDate = new Date();
			return dateObject.getTime() < currentDate.getTime()
		}
	},
	methods: {
		fetchData() {
			this.loading = true
			this.apiCall({ destination: 'get_item_class_detail', data: { link_name: this.link_name, link_from: this.$route.query.link_from } }).then(obj => {
				this.loading = false
				if (obj.status == 'success' && obj.detail) {
					this.detail = obj.detail
					if (obj.detail.participants) {
						this.participants = obj.detail.participants
					}
					if (obj.user_contact) {
						this.userContact = obj.user_contact
					}
					this.reservationCount = 0 // will be set per date when selected
					if (this.$route.query.date) {
						this.populateDates(this.$route.query.date)
					} else if (this.detail.recurring) {
						this.populateCalendarItems(this.showDate.getFullYear() + '-' + (this.showDate.getMonth() + 1).toString().padStart(2, '0') + '-01')
					} else if (this.detail.dates.length > 1) {
						this.populateCalendarItems(this.showDate.getFullYear() + '-' + (this.showDate.getMonth() + 1).toString().padStart(2, '0') + '-01')
					} else {
						// non-recurring class, so populate date(s)
						this.detail.dates.forEach((thisDate) => {
							let dateSelected = this.dateFormat(thisDate.date) + ' ' + this.timeFormat(thisDate.date + ' ' + thisDate.start_time) + ' - ' + this.timeFormat(thisDate.date + ' ' + thisDate.end_time)
							if (thisDate.instructor || this.detail.default_instructor) {
								dateSelected += ', Instructor: ' + (thisDate.instructor || this.detail.default_instructor)
							}
							if (thisDate.date < this.today) {
								this.classExpired = true
							}
							this.datesSelected.push(dateSelected)
							if (thisDate.sessions.length > 0) {
								thisDate.sessions.forEach((session) => {
									let sessionSelected = this.dateFormat(session.date) + ' ' + this.timeFormat(session.date + ' ' + session.start_time) + ' - ' + this.timeFormat(session.date + ' ' + session.end_time)
									if (session.instructor || this.detail.default_instructor) {
										sessionSelected +=', Instructor: ' + (session.instructor || this.detail.default_instructor)
									}
									this.datesSelected.push(sessionSelected)
								})
							}
						})
						if (!this.classExpired) {
							this.getDateReservations()
						}
					}
					this.addParticipant()
				} else {
					this.message = obj.message
				}
			}).catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		populateDates(forDate) {
			this.classExpired = false
			if (this.detail.recurring) {
				let thisDate = this.detail.dates[0]
				// get the number of days difference between query date and first returned date
				let originalDate = new Date(thisDate.date)
				let testDate = new Date(forDate)
				let timeDifference = testDate.getTime() - originalDate.getTime()
				let dayDifference = timeDifference / (1000 * 3600 * 24)
				// then add that number of days to each date in the returned date array
				let startDateObject
				let endDateObject
				//this.detail.dates.forEach((thisDate) => {
					startDateObject = new Date(thisDate.date + ' ' + thisDate.start_time)
					startDateObject.setDate(startDateObject.getDate() + dayDifference)
					endDateObject = new Date(thisDate.date + ' ' + thisDate.end_time)
					let dateSelected = this.dateFormat(startDateObject) + ' ' + this.timeFormat(startDateObject) + ' - ' + this.timeFormat(endDateObject)
					if (thisDate.instructor || this.detail.default_instructor) {
						dateSelected +=', Instructor: ' + (thisDate.instructor || this.detail.default_instructor)
					}
					let startDateCompare = startDateObject.getFullYear() + '-' + (startDateObject.getMonth()+1).toString().padStart(2,'0') + '-' + startDateObject.getDate().toString().padStart(2,'0')
					if (startDateCompare < this.today) {
						this.classExpired = true
					}
					this.datesSelected.push(dateSelected)
					if (thisDate.sessions.length > 0) {
						thisDate.sessions.forEach((session) => {
							startDateObject = new Date(session.date + ' ' + session.start_time)
							startDateObject.setDate(startDateObject.getDate() + dayDifference)
							endDateObject = new Date(session.date + ' ' + session.end_time)
							let sessionSelected = this.dateFormat(startDateObject) + ' ' + this.timeFormat(startDateObject) + ' - ' + this.timeFormat(endDateObject)
							if (session.instructor || this.detail.default_instructor) {
								sessionSelected +=', Instructor: ' + (session.instructor || this.detail.default_instructor)
							}
							this.datesSelected.push(sessionSelected)
						})
					}
				//})
			} else {
				// set thisDate to the date in the array with the same date
				let thisDate = this.detail.dates.find(date => date.date == forDate)
				let startDateObject = new Date(thisDate.date + ' ' + thisDate.start_time)
				let endDateObject = new Date(thisDate.date + ' ' + thisDate.end_time)
				let dateSelected = this.dateFormat(startDateObject) + ' ' + this.timeFormat(startDateObject) + ' - ' + this.timeFormat(endDateObject)
				if (thisDate.instructor || this.detail.default_instructor) {
					dateSelected +=', Instructor: ' + (thisDate.instructor || this.detail.default_instructor)
				}
				let startDateCompare = startDateObject.getFullYear() + '-' + (startDateObject.getMonth()+1).toString().padStart(2,'0') + '-' + startDateObject.getDate().toString().padStart(2,'0')
				if (startDateCompare < this.today) {
					this.classExpired = true
				}
				this.datesSelected.push(dateSelected)
				if (thisDate.sessions.length > 0) {
					thisDate.sessions.forEach((session) => {
						startDateObject = new Date(session.date + ' ' + session.start_time)
						endDateObject = new Date(session.date + ' ' + session.end_time)
						let sessionSelected = this.dateFormat(startDateObject) + ' ' + this.timeFormat(startDateObject) + ' - ' + this.timeFormat(endDateObject)
						if (session.instructor || this.detail.default_instructor) {
							sessionSelected +=', Instructor: ' + (session.instructor || this.detail.default_instructor)
						}
						this.datesSelected.push(sessionSelected)
					})
				}
			}
			if (!this.classExpired) {
				this.getDateReservations()
			}
		},
		getDateReservations() {
			// just need the date of the first date selected
			this.detail.reservation_count = 0
			let dateSelectedParts = this.datesSelected[0].split(' ')
			this.apiCall({ destination: 'get_list_class_reservations', data: { class_detail_id: this.detail.class_detail_id, reservation_date: dateSelectedParts[0], count_only: 1 } }).then(obj => {
					if (obj.status == 'success') {
						this.reservationCount = obj.reservation_count
					}
				}).catch(error => {
					this.loading = false
					console.log(error)
				})				
		},
		populateParticipants() {
			if (this.participants.length > this.seatsSelected) {
				this.participants.splice(this.seatsSelected)
			}
			for (let i = 0; i < this.seatsSelected; i++) {
				if (!this.participants[i]) {
					this.addParticipant()
				}
			}
		},
		addParticipant() {
			let participant = {
				first_name: '',
				last_name: '',
				email_address: '',
				phone_number: '',
				email_notifications: false,
				text_notifications: false
			}
			if (this.detail.registration_required_fields) {
				if (this.detail.registration_required_fields.birthdate) {
					participant.birthdate = ''
				}
				if (this.detail.registration_required_fields.emergency_contact) {
					participant.emergency_contact = ''
				}
			}
			if (this.userContact.contact_id && this.participants.length == 0) {
				participant.first_name = this.userContact.first_name
				participant.last_name = this.userContact.last_name
				participant.email_address = this.userContact.email_address
				participant.phone_number = this.userContact.phone_number
			}
			this.participants.push(participant)
		},
		populateCalendarItems(calendarStartDate) {
			if (this.loading) { return }
			this.loading = true
			this.calendarItems = []
			// get start and end date / time, regardless of how many sessions in the set
			let startDate = this.detail.dates[0].date
			let startTime = this.detail.dates[0].start_time
			let endDate = this.detail.dates[this.detail.dates.length - 1].date
			let endTime = this.detail.dates[this.detail.dates.length - 1].end_time
			// recurring?
			if (this.detail.recurring) {
				let calendarStart = typeof calendarStartDate == 'object' ? calendarStartDate : new Date(calendarStartDate)
				let calendarEnd = new Date(calendarStart.getFullYear(), calendarStart.getMonth()+1, 1) // first day of next month
				// is there a repeat end date?
				if (this.detail.class_frequency_end_date) {
					let freqencyEnd = new Date(this.detail.class_frequency_end_date)
					freqencyEnd.setDate(freqencyEnd.getDate() + 1)
					if (freqencyEnd.getTime() < calendarEnd.getTime()) {
						calendarEnd = freqencyEnd;
					}
				}
				let index = 0
				let dEnd = new Date(endDate)
				switch (this.detail.class_frequency_code) {
					case 'DAY':
						for (var d = new Date(startDate); d <= calendarEnd; d.setDate(d.getDate() + 1)) {
							this.calendarItems.push({ id: index++, startDate: (d.getFullYear()+'-'+(d.getMonth()+1).toString().padStart(2,'0')+'-'+(d.getDate()+1).toString().padStart(2,'0')) + ' ' + startTime, endDate: (dEnd.getFullYear()+'-'+(dEnd.getMonth()+1).toString().padStart(2,'0')+'-'+(dEnd.getDate()+1).toString().padStart(2,'0')) + ' ' + endTime, title: '<i class="fa fa-check"></i>' })
							dEnd.setDate(dEnd.getDate() + 1)
						}
						break;
					case 'WEEK':
						let multiplier = this.detail.class_frequency_unit || 1
						for (var d = new Date(startDate); d <= calendarEnd; d.setDate(d.getDate() + (7 * multiplier))) {
							this.calendarItems.push({ id: index++, startDate: (d.getFullYear()+'-'+(d.getMonth()+1).toString().padStart(2,'0')+'-'+(d.getDate()+1).toString().padStart(2,'0')) + ' ' + startTime, endDate: (dEnd.getFullYear()+'-'+(dEnd.getMonth()+1).toString().padStart(2,'0')+'-'+(dEnd.getDate()+1).toString().padStart(2,'0')) + ' ' + endTime, title: '<i class="fa fa-check"></i>' })
							dEnd.setDate(dEnd.getDate() + (7 * multiplier))
						}
						break;
					case 'MONTH':	
						let startDateParts = startDate.split('-')
						let startDateObject = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2])
						// don't populate calendar if it's before class start year + month
						if (calendarStart.getFullYear() == startDateObject.getFullYear() && calendarStart.getMonth() < startDateObject.getMonth()) {
							break
						}
						let startDayOfMonth = startDateObject.getDate()
						let endDateParts = endDate.split('-')
						let endDateObject = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2])
						let endDayOfMonth = endDateObject.getDate()
						if (this.detail.class_frequency_month_as_date != 1) {
							let dayOfWeek = new Date(startDateObject).getDay()
							let ordinal = Math.floor(startDayOfMonth / 7) + 1
							let ordinalIndex = 1
							// iterate through each day in the calendar month
							for (var d = calendarStart; d <= calendarEnd; d.setDate(d.getDate() + 1)) {
								if (d.getDay() == dayOfWeek) { // it's the right day of the week!
									if (ordinalIndex == ordinal) { // it's the right nth day of month!
										this.calendarItems.push({ id: 1, startDate: (d.getFullYear()+'-'+(d.getMonth()+1).toString().padStart(2,'0')+'-'+d.getDate().toString().padStart(2,'0')) + ' ' + startTime, endDate: (d.getFullYear()+'-'+(d.getMonth()+1).toString().padStart(2,'0')+'-'+d.getDate().toString().padStart(2,'0')) + ' ' + endTime, title: '<i class="fa fa-check"></i>' })
										break
									} else {
										ordinalIndex += 1
									}
								}
							}
						} else {
							this.calendarItems.push({ id: 1, startDate: calendarStart.getFullYear()+'-'+(calendarStart.getMonth()+1).toString().padStart(2,'0')+'-'+(startDayOfMonth.toString().padStart(2,'0')) + ' ' + startTime, endDate: calendarStart.getFullYear()+'-'+(calendarStart.getMonth()+1).toString().padStart(2,'0')+'-'+(endDayOfMonth.toString().padStart(2,'0')) + ' ' + endTime, title: '<i class="fa fa-check"></i>' })
						}
						break;
				}
			} else {
				this.detail.dates.forEach((thisDate) => {
					this.calendarItems.push({ id: thisDate.class_date_id + '_' + thisDate.date, startDate: thisDate.date + ' ' + thisDate.start_time, endDate: thisDate.date + ' ' + thisDate.end_time, title: '<i class="fa fa-check"></i>' })
				})
			}
			this.loading = false
		},
		changeMonth(event) {
			this.populateCalendarItems(event.periodStart)
		},
		selectCalendarItem(event) {
			this.populateDates(this.dateFormatNormalized(event.startDate))
		},
		setShowDate(d) {
			this.showDate = d;
		},
		moment() {
			return moment();
		},
		dateFormat(dateObject) {
			return moment(dateObject.toString()).format('MM/DD/YYYY');
		},
		timeFormat(dateObject) {
			return moment(dateObject.toString()).format("h:mm a");
		},
		dateTimeFormat(dateObject) {
			return moment(dateObject.toString()).format('MM/DD/YYYY HH:mm a');
		},
		dateFormatNormalized(dateObject) {
			return moment(dateObject.toString()).format('YYYY-MM-DD');
		},
		addClassToCart() {
			if (!this.canAddToCart) { return }
			this.addingToCartMessage = ''
			if (!this.inCart) {
				if (this.datesSelected.length < 1) {
					this.addingToCartMessage = 'Please select a class date.'
					return
				}
				this.participants.forEach((participant, index) => {
					for (const [key, value] of Object.entries(participant)) {
						if (typeof value == 'string' && !value) {
							this.addingToCartMessage = 'Participant ' + (index + 1) + ', ' + this.$caplitalizeWords(key) + ' is required.'
							return
						}
						if (key == 'email_address' && !this.$isValidEmailAddress(value)) {
							this.addingToCartMessage = 'Participant ' + (index + 1) + ', please enter a valid Email Address.'
							return
						}
						if (key == 'phone_number' && !this.$isValidPhoneNumber(value)) {
							this.addingToCartMessage = 'Participant ' + (index + 1) + ', please enter a full Phone Number.'
							return
						}
					}
				})
				if (!this.addingToCartMessage && this.detail.registration_terms && !this.detail.agree_to_terms) {
					this.addingToCartMessage = 'Please read and agree to the Rules and Requirements for this class.'
				}
				if (this.addingToCartMessage) {
					return
				}
				this.addingToCartMessage = 'Adding class registration to cart...'
			} else {
				this.addingToCartMessage = 'Removing class registration from cart...'
			}
			this.addingToCart = true
			let dateSelectedParts = this.datesSelected[0].split(' ')
			let postData = {
				class_detail_id: this.detail.class_detail_id,
				product_id: this.detail.product_id,
				price: this.detail.price,
				quantity: this.participants.length,
				available: this.detail.seats_available,
				class_date: dateSelectedParts[0],
				participants: this.participants,
                early_registration_closes_days_before: this.detail.early_registration_closes_days_before,
                early_registration_discount: this.detail.early_registration_discount,
				remove: this.inCart
			}
			this.apiCall({ destination: 'add_class_registration_to_cart', data: postData }).then(obj => {
				this.addingToCart = false
				if (obj.status == 'success') {
					if (this.inCart) {
						this.inCart = false 
						this.addingToCartMessage = 'Registration removed from cart!'
					} else {
						this.inCart = true 
						this.addingToCartMessage = 'Registration added to cart!'
					}
					setTimeout(() => { this.addingToCartMessage = '' }, 2000)
				} else {
					this.addingToCartMessage = obj.message
				}
			}).catch(error => {
				console.log(error)
			})
		},
		addProductToCart() {

		},
		backToList() {
			this.$router.push('/classes' + (this.$route.query.from ? '/' + this.$route.query.from : ''))
		}
	},
	filters: {
		dateMoment(date) {
			return moment(date).format('MMMM DD YYYY');
		},
		timeMoment(time) {
			return moment(time, ["HH:mm"]).format("hh:mm a");
		}
	}
}
</script>

<style lang="scss" scoped>
.large-modal {
	.inner {
		max-height: 60vh;
		max-width: 60vw;
		.content {
			max-height: 50vh;
		}
		@media all and (max-width: 1023px) {
			max-height: 100%;
			max-width: 100%;
		}
	}
}

.description, .class-meta {
	padding-left: 0.75em;
}
.registration-section {
	color: #000;
	background-color: #fff;
	padding: 0.5em 1.5em 1.5em 1.5em;
}
.form-group {
	margin-bottom: 1em;
}
a {
	cursor: pointer;
}
</style>

<style lang="scss">
.small-class-calendar {
	margin: 1em 0;
	.theme-default .cv-header .periodLabel {
		display: block;
		text-align: right;
		font-size: 1.75em;
	}
	.cv-header {
		background-color: #fff;
		color: #000;
		.cv-header-nav {
			display: flex;
			button {
				color: #494949;
			}
		}
	}
	.cv-header-days {
		background-color: #f0f0f0;
		color: #494949;
	}
	.cv-week {
		min-height: 5em !important;
	}
	.cv-day {
		color: #000;
		height: 5em;
		background-color: #fff;
	}
	.cv-item {
		font-size: 1em;
		height: 3em !important;
		width: 12% !important;
		margin: 0.33em;
		padding: 1em;
		text-align: center;
		cursor: pointer;
		background-color: green;
		border-color: green;
		color: white;
	}
    .theme-default .cv-item.past {
        background-color: #B1B1B1;
		border-color: #B1B1B1;
		color: #B1B1B1;
        pointer-events: none;
    }
}
</style>